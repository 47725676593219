





































import { defineComponent, ref } from '@vue/composition-api';
import { NotificationContractIcon } from '../icons';

export default defineComponent({
    name: 'ContractDigest',
    components: { NotificationContractIcon },
    props: {
        notification: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const isHovered = ref(false);

        const getContractTitle = (contractType: string) => {
            let title = '';
            switch (contractType) {
                case 'contract.created':
                    title = 'Contracts that were created and involve your Organization: ';
                    break;
                case 'contract.accepted':
                    title = ' Contracts that were accepted and involve your Organization: ';
                    break;
                case 'contract.rejected':
                    title = 'Contracts that were rejected and involve your Organization: ';
                    break;
                case 'contract.activated':
                    title = 'Contracts that became active and involve your Organization: ';
                    break;
                case 'contract.negotiation':
                    title = 'Contracts under negotiation and involve your Organization: ';
                    break;
                case 'contract.extended':
                    title = 'Contracts that were extended and involve your Organization: ';
                    break;
                case 'contract.revised':
                    title = 'Contracts that were revised and involve your Organization: ';
                    break;
                case 'contract.reactivated':
                    title = 'Contracts that were reactivated and involve your Organization: ';
                    break;
                case 'contract.draft_signed':
                    title = 'Draft Contracts sent to your Organization for data assets you are interested to acquire: ';
                    break;
                case 'contract.bundle.rejected':
                    title = 'Bundle contracts that were rejected and involves your Organisation: ';
                    break;
                case 'contract.bundle.activated':
                    title = 'Bundle contracts that were activated and involves your Organisation: ';
                    break;
                default:
                    break;
            }
            return title;
        };

        const getUrl = (key: string, contract: any) => {
            return { name: 'contract-details', params: { id: contract.payload.contractId, type: 'details' } };
        };

        const getContractMessage = (contract: any) => {
            let contractRelationVerb = '';
            let contracRelation = '';

            if (contract.payload.consumerOrgId === contract.payload.organisationId) {
                contractRelationVerb = 'Acquisition';
                contracRelation = 'from';
            } else {
                contractRelationVerb = 'Sharing';
                contracRelation = 'with';
            }
            const contractMessage = {
                contractId: contract.payload.contractId,
                verb: contractRelationVerb,
                relation: contracRelation,
                noun: contract.payload.organisationId
                    ? `organisation ${contract.payload.organisation.legalName}`
                    : `user ${contract.payload.user.firstName} ${contract.payload.user.lastName}`,
                endPhrase: '',
            };

            return { ...contractMessage };
        };

        return {
            isHovered,
            getContractTitle,
            getContractMessage,
            getUrl,
        };
    },
});
