












































import TailwindAccodion from '@/app/components/tailwind-accordion/TailwindAccordion.vue';
import TailwindAccodionCard from '@/app/components/tailwind-accordion/TailwindAccordionCard.vue';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { NotificationAPI } from '../api';
import AdministratorDigest from '../components/eventDigests/AdministratorDigest.vue';
import DataCheckinJobDigest from '../components/eventDigests/DataCheckinJobDigest.vue';
import ModelSuggestionDigest from '../components/eventDigests/ModelSuggestionDigest.vue';
import WorkflowDigest from '../components/eventDigests/WorkflowDigest.vue';
import ContractDigest from '../components/eventDigests/ContractDigest.vue';
import {
    NotificationAdministratorIcon,
    NotificationContractIcon,
    NotificationSuggestionIcon,
    NotificationWorkflowIcon,
} from '../components/icons';
import NotificationDataCheckinJobIcon from '../components/icons/NotificationDataCheckinJobIcon.vue';

export default defineComponent({
    name: 'NotificationsDigest',
    metaInfo() {
        return {
            title: `Notifications Digest for period ${(this as any).startDate} - ${(this as any).endDate}`,
        };
    },
    components: { TailwindAccodion, TailwindAccodionCard },
    props: {
        notificationGroups: {
            type: Object,
            required: false,
        },
        createdAt: {
            type: String,
            required: false,
        },
        digestType: {
            type: String,
            required: false,
        },
    },
    setup(props, { root }) {
        const { exec } = useAxios(true);
        const notificationGroups = ref<any>(null);
        const createdAt = ref<string>('');
        const digestType = ref<string>('');

        const fetchDigestNotification = () => {
            exec(NotificationAPI.fetchOne(Number(root.$route.params.id)))
                .then((res: any) => {
                    notificationGroups.value = res.data?.payload;
                    createdAt.value = res.data?.createdAt;
                    digestType.value = res.data?.eventType;
                })
                .catch((e) => {
                    (root as any).$toastr.e('Unable to load notification', 'Error');
                    throw e;
                });
        };

        if (props.notificationGroups && props.createdAt && props.digestType) {
            notificationGroups.value = props.notificationGroups;
            createdAt.value = props.createdAt;
            digestType.value = props.digestType;
        } else {
            fetchDigestNotification();
        }

        const components = {
            Contract: {
                header: 'Quick Contracts(s) Update',
                component: ContractDigest,
                icon: NotificationContractIcon,
            },
            Administration: {
                header: 'Quick Administrator Update:',
                component: AdministratorDigest,
                icon: NotificationAdministratorIcon,
            },
            ModelSuggestion: {
                header: 'Quick Data Model(s) Update',
                component: ModelSuggestionDigest,
                icon: NotificationSuggestionIcon,
            },
            DataCheckinJob: {
                header:
                    'Quick Data Check-in Pipeline(s) Update: <span class="text-neutral-600">Review successful and failed executions</span>',
                component: DataCheckinJobDigest,
                icon: NotificationDataCheckinJobIcon,
            },
            Workflow: {
                header:
                    'Quick Analytics pipeline(s) Update: <span class="text-neutral-600">Review successful and failed executions</span>',
                component: WorkflowDigest,
                icon: NotificationWorkflowIcon,
            },
        };

        const startDate = computed(() => {
            const end = new Date(createdAt.value);
            const start = new Date(createdAt.value);
            const ndays = digestType.value === 'daily_digest' ? 1 : 7;
            start.setTime(end.getTime() - ndays * 24 * 60 * 60 * 1000);
            return start.toLocaleString();
        });
        const endDate = computed(() => {
            return new Date(createdAt.value as string).toLocaleString();
        });

        const filterNotificationGroups = computed(() => {
            if (notificationGroups.value) {
                const filterNot = Object.keys(notificationGroups.value)
                    .filter((key) => key !== 'baseUrl' && key !== 'referenceId' && key !== 'referenceType')
                    .reduce((obj, key) => {
                        return R.assoc(key, notificationGroups.value[key], obj);
                    }, {});

                return filterNot;
            }
            return null;
        });

        watch(
            () => root.$route.params?.id,
            async () => {
                fetchDigestNotification();
            },
        );

        return {
            TailwindAccodion,
            TailwindAccodionCard,
            components,
            startDate,
            endDate,
            filterNotificationGroups,
        };
    },
});
