







































import { computed, defineComponent, ref } from '@vue/composition-api';
import { NotificationDataCheckinJobIcon } from '../icons';

export default defineComponent({
    name: 'DataCheckinJobDigest',
    components: { NotificationDataCheckinJobIcon },
    props: {
        notification: {
            type: Object,
            required: true,
        },
        referenceId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const isHovered = ref(false);

        const getPaylod = computed(() => {
            const payload = {
                name: 'data-checkin-jobs',
                params: {
                    id: parseInt(props.referenceId, 10),
                    status: props.notification.eventType,
                    type: props.notification.payload.referenceType,
                },
            };
            return payload;
        });

        return {
            isHovered,
            getPaylod,
        };
    },
});
